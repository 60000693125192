import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/react-scroll-utilities/react-scroll-utilities/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`React Scroll Utilities provides tools to simplify many use-cases with
scrolling parts in React, whilst leaving you full control.`}</p>
    <p><strong parentName="p">{`The library is currently in an experimental state and subject to breaking
API changes.`}</strong></p>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`List virtualization`}</li>
      <li parentName="ul">{`Scroll anchoring (coming soon)`}</li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install react-scroll-utilities
# or
yarn add react-scroll-utilities
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      